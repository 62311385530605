if process.env.NODE_ENV == 'test'
  frontendUrl = 'http://test.kredit.local.nu'
  backendUrl  = 'http://localhost:9000'
else
  frontendUrl = process.env.KREDIT_FRONTEND_URL ? 'http://localhost:3000'
  backendUrl  = process.env.KREDIT_BACKEND_URL  ? 'http://localhost:8000'

if process.env.NODE_ENV == 'production'
  deployName = process.env.KREDIT_DEPLOY_NAME
  deployType = process.env.KREDIT_DEPLOY_TYPE ? 'unspecified'
  sentryDsn  = process.env.KREDIT_SENTRY_DSN

defaultClientPath = '/kredit/transaction_history'

export default config = { frontendUrl, backendUrl, defaultClientPath, deployName, deployType, sentryDsn }
